import { getDefaultConfig } from '@rainbow-me/rainbowkit';
import { SupportChains } from './chains';
import { metaMaskWallet, okxWallet, rainbowWallet, walletConnectWallet, coinbaseWallet } from '@rainbow-me/rainbowkit/wallets';

export const wagmiConfig = getDefaultConfig({
  appName: 'CryptoRumble',
  projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID || '2eadf4ac093e00416833ba6af0def414',
  chains: SupportChains,
  ssr: true,
  wallets: [
    {
      groupName: 'Recommended',
      wallets: [metaMaskWallet, okxWallet, walletConnectWallet, rainbowWallet, coinbaseWallet],
    },
  ]
});
